import PropTypes from 'prop-types';
import React from 'react';
import { useTheme } from '@mui/material';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { styled } from '@mui/material/styles';
import { KeyboardArrowRight, KeyboardArrowLeft } from '@mui/icons-material';

const Image = styled('img')(() => ({
    width: '100%',
}));

function PrevArrow({ className, style, onClick }) {
    const theme = useTheme();

    return (
        <KeyboardArrowLeft
            className={className}
            style={{ ...style, color: theme.palette.primary.main, width: '3vw', height: '3vw', left: '-3vw' }}
            onClick={onClick}
        />
    );
}

function NextArrow({ className, style, onClick }) {
    const theme = useTheme();

    return (
        <KeyboardArrowRight
            className={className}
            style={{ ...style, color: theme.palette.primary.main, width: '3vw', height: '3vw', right: '-3vw' }}
            onClick={onClick}
        />
    );
}

/**
 * Make sure:
 *  - That the images have the SAME aspect ratio, otherwise the carousel will not work as expected.
 *  - That the carousel is use under the page fold, since it causes a page shift (until WEBSTORE-408 is fixed).
 */
function WidgetCarousel({ images, slidesToShow = 1, autoplay = false }) {
    const settings = {
        arrows: true,
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: '20%',
        autoplaySpeed: 2000,
        autoplay,
        prevArrow: <PrevArrow />,
        nextArrow: <NextArrow />,
        lazyLoad: true,
        responsive: [
            {
                breakpoint: 1260,
                settings: {
                    arrows: false,
                },
            },
        ],
    };
    return (
        <Slider {...settings}>
            {images.map((image, index) => (
                <div key={index}>
                    <Image src={image.src} alt={image.description} />
                </div>
            ))}
        </Slider>
    );
}

WidgetCarousel.propTypes = {
    images: PropTypes.arrayOf(PropTypes.object).isRequired,
    slidesToShow: PropTypes.number,
    autoplay: PropTypes.bool,
};

export default WidgetCarousel;
