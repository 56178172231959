import PropTypes from 'prop-types';
import React from 'react';
import { Carousel, carouselClasses } from '@athenagroup/components';
import { grey } from '@mui/material/colors';
import Markdown from '../../markdown/Markdown';

function TextImageTabs({ title, items, animation }) {
    return (
        <Carousel
            title={title}
            variant="tabs"
            items={items.map(item => ({ ...item,
                tabTitle: item.title,
                description: <Markdown>{item.description}</Markdown> }))}
            animation={animation}
            sx={{
                [`.${carouselClasses.panelContainer} div`]: {
                    background: grey[50], alignItems: 'start',
                },
            }}
        />
    );
}

TextImageTabs.propTypes = {
    title: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape({
        imgSide: PropTypes.oneOf(['left', 'right']),
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        imgSrc: PropTypes.string.isRequired,
    })).isRequired,
    animation: PropTypes.string,
};

export default TextImageTabs;
