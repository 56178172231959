import { styled } from '@mui/material/styles';
import { ChevronRightSharp } from '@mui/icons-material';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Card, CardContent, CardHeader } from '@athenagroup/components';
import { getPagePath, getRouterPath } from '../../../helpers/path';
import staticPages from '../../../pages/static/staticPages';
import WidgetAnimation from './WidgetAnimation';
import useTransformedUri from '../../../hoc/hooks/useTransformedUri';

const PREFIX = 'HomePageWidgetThumbnails';

const classes = {
    wrapper: `${PREFIX}-wrapper`,
    gridItem: `${PREFIX}-gridItem`,
    cardContent: `${PREFIX}-cardContent`,
    content: `${PREFIX}-content`,
    icon: `${PREFIX}-icon`,
};

const Root = styled('div')(({ theme, linkColor }) => ({
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill,minmax(250px, 1fr))',
    gridAutoRows: 'minmax(min-content, max-content)',
    gridGap: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
        gridTemplateColumns: 'repeat(1, 1fr)',
    },
    [`& .${classes.gridItem}`]: {
        display: 'flex',
        flexDirection: 'column',
        '& a': {
            display: 'flex',
            color: linkColor || theme.palette.primary.main,
            alignItems: 'center',
            flexWrap: 'wrap',
            fontWeight: 600,
        },
    },
    [`& .${classes.cardContent}`]: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
    },
    [`& .${classes.content}`]: {
        flex: 1,
    },
}));

export function Thumbnail({ item }) {
    const { t } = useTranslation();
    const to = useTransformedUri(item.to);

    return (
        <Card className={classes.gridItem}>
            <CardHeader
                icon={{ url: item.iconSrc, alt: item.title }}
                title={item.title}
            />
            <CardContent className={classes.cardContent}>
                <div className={classes.content}>{item.description}</div>
                {item.href && (
                    <a href={item.href} role="menuitem" target="_blank" rel="noopener noreferrer">
                        {t('home.widgets.read_more')}
                        {' '}
                        <ChevronRightSharp />
                    </a>
                )}
                {item.to && (
                    <Link to={to} role="menuitem">
                        {t('home.widgets.read_more')}
                        {' '}
                        <ChevronRightSharp />
                    </Link>
                )}
            </CardContent>
        </Card>
    );
}

function Thumbnails({ items, linkColor, animation }) {
    if (!items) {
        return null;
    }
    return (
        <Root linkColor={linkColor}>
            <WidgetAnimation animation={animation} duration={300} delay={50} fraction={0.1} triggerOnce>
                {items.map(item => (
                    <Thumbnail
                        item={item}
                        key={item.title}
                    />
                ))}
            </WidgetAnimation>
        </Root>
    );
}

function customPropType(props, propName, componentName) {
    if (!props.href && !props.to) {
        return new Error(`One of props 'href' or 'to' was not specified in '${componentName}'.`);
    }
    return true;
}

Thumbnails.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({
        href: customPropType,
        to: customPropType,
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        imgSrc: PropTypes.string.isRequired,
    })).isRequired,
    linkColor: PropTypes.string,
    animation: PropTypes.string,
};

export default Thumbnails;
