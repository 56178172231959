import PropTypes from 'prop-types';
import React from 'react';
import { TextImage, textImageClasses } from '@athenagroup/components';
import Markdown from '../../markdown/Markdown';
import { LinkComponent } from '../../general/LinkComponent';
import useTransformedUri from '../../../hoc/hooks/useTransformedUri';

function HomePageWidgetTextImage({ imgSide, description, button, imgSrc, title, animation }) {
    const to = useTransformedUri(button?.to);

    return (
        <TextImage
            title={title}
            description={<Markdown>{description}</Markdown>}
            imgSrc={imgSrc}
            imgAlt=""
            imgSide={imgSide}
            variant="backdrop"
            alignImage="center"
            justifyContent="center"
            animation={animation}
            buttonLink={!to ? { href: button?.to } : { to }}
            buttonLabel={button?.label}
            ButtonLinkComponent={LinkComponent}
            sx={{ [`& .${textImageClasses.titleDescriptionButton} a`]: { borderRadius: '2px' } }}
            applyBorderRadius
        />
    );
}

HomePageWidgetTextImage.disableTitle = true;

HomePageWidgetTextImage.propTypes = {
    imgSide: PropTypes.oneOf(['left', 'right']),
    description: PropTypes.string.isRequired,
    button: PropTypes.shape({
        label: PropTypes.string,
        to: PropTypes.string,
    }),
    imgSrc: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    animation: PropTypes.string,
};

export default HomePageWidgetTextImage;
