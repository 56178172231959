import React from 'react';
import { alpha, Grid, styled } from '@mui/material';

const baseClass = 'RoleCard';
const classes = {
    title: `${baseClass}-title`,
    content: `${baseClass}-content`,
    cardTitle: `${baseClass}-cardTitle`,
    description: `${baseClass}-description`,
};

const RootDiv = styled('div')(({ theme }) => ({

    [`& .${classes.content}`]: {
        backgroundColor: '#F1F5F7',
        padding: '3.5rem 2rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        borderRadius: 4,
        transition: 'all .18s cubic-bezier(.55,.025,.455,.98)',
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.black, 0.7),
            cursor: 'pointer',
            [`& .${classes.cardTitle}`]: {
                color: theme.palette.common.white,
            },
            [`& .${classes.description}`]: {
                color: theme.palette.common.white,
            },
        },
    },
    [`& .${classes.cardTitle}`]: {
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightBold,
        margin: 0,
        fontSize: '1.125rem',
        textAlign: 'center',
        transition: 'all .18s cubic-bezier(.55,.025,.455,.98)',
    },
    [`& .${classes.description}`]: {
        margin: 0,
        color: theme.palette.grey[600],
        textAlign: 'center',
        transition: 'all .18s cubic-bezier(.55,.025,.455,.98)',
    },
}));

export default function RoleCards({ items }) {
    return (
        <RootDiv>
            <Grid container spacing={2.5}>
                {items.map((item, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <a href={item.href} target="_blank" rel="noreferrer" className={classes.content}>
                            <p className={classes.cardTitle}>{item.title}</p>
                            <p className={classes.description}>{item.description}</p>
                        </a>
                    </Grid>
                ))}
            </Grid>
        </RootDiv>
    );
}
