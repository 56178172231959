import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import staticPages from '../../pages/static/staticPages';
import { getPagePath, getRouterPath } from '../../helpers/path';

function useTransformedUri(to) {
    const { i18n } = useTranslation();
    return useMemo(() => {
        if (!to) return undefined;

        if (to.indexOf('key:') === 0) {
            const pages = staticPages(i18n);
            return getPagePath(pages, to.substring(4));
        }

        if (to.indexOf('route:') === 0) {
            return getRouterPath(i18n, to.substring(6), i18n.language, true);
        }

        return undefined;
    }, [to, i18n]);
}

export default useTransformedUri;
